import { defineStore } from 'pinia';
import { content as contentApi, sites } from '@/api';
import { SitesStore } from '@/helpers/types';
import ContentFactory from '@/factories/ContentFactory';
import { ContentOrder } from '@/api/modules/content';

export const useSitesStore = defineStore('SitesStore', {
  state: (): SitesStore => {
    return {
      data: null,
      creature: {
        name: '',
        slug: '',
        content_group: {
          name: '',
        },
        languages: [],
      },
      site: null,
      isLoading: false,
      templates: null,
      content: {
        id: null,
        parent_id: null,
        parents_ids: [],
        content_template_id: null,
        content_group_id: null,
        title: null,
        slug: null,
        field_groups: [],
        content_template: {
          id: null,
          description: null,
          field_group_templates: [],
          name: null,
        },
      },
      contents: [],
    };
  },
  getters: {
    getSitesData: (state) => state.data,
    getCurrentSite: (state) => state.site,
    getCreature: (state) => state.creature,
    isLoaded: (state) => state.isLoading,
    getTemplates: (state) => state.templates,
    getContent: (state) => state.content,
    getContents: (state) => state.contents,
  },
  actions: {
    async sitesData() {
      this.isLoading = true;
      return await sites.index().then((response) => {
        this.data = response.data;
        this.isLoading = false;
        return response.data;
      });
    },

    async show(id: number) {
      this.isLoading = true;
      return await sites.show(id).then((response) => {
        this.site = response.data;
        this.isLoading = false;
        return response.data;
      });
    },

    async storeSiteData(payload: {
      name: string;
      slug: string;
      content_group: { name: string };
      languages: number[];
    }) {
      return await sites.store(payload).then((response) => {
        return response.data;
      });
    },

    async save() {
      if (this.site) {
        if (this.site?.id !== null) {
          const { id, name, slug, languages, content_group, domain } =
            this.site;
          await sites.update({
            id,
            name,
            slug,
            domain,
            languages,
            content_group: {
              name: content_group.name,
            },
          });
          return;
        }
        //TODO:: Add create method
      }
    },

    async remove(id: number) {
      if (this.site) {
        if (this.site?.id !== null) {
          await sites.remove(id);
          return;
        }
      }
    },

    async fetchTemplates() {
      const { data } = await sites.templates();
      this.templates = data;
    },

    clearSitesStore() {
      this.data = null;
      this.site = null;
      this.isLoading = false;
    },

    async contentSave(templateID: number | null) {
      if (this.content) {
        const site_id = this.getCurrentSite?.id;
        const content_group_id = this.getCurrentSite?.content_group?.id;
        if (content_group_id) {
          this.content.content_group_id = content_group_id;
        }
        this.content.content_template_id = templateID;
        if (this.content.id) {
          await contentApi.update(this.content);
        } else {
          await contentApi.store({
            ...this.content,
            site_id,
          });
        }
      }
    },

    async contentSearch(query: { query?: string; site_id?: number }) {
      const { data } = await contentApi.index(query);
      this.contents = data;
    },

    async contentShow(id: number) {
      const { data } = await contentApi.show(id);
      this.content = ContentFactory.create(data);
    },

    async updateFieldValue(d: any) {
      await contentApi.fieldUpdate(d);
    },

    async deleteContentPage(id: number) {
      try {
        await contentApi.remove(id);
        if (this.site !== null) {
          await this.show(Number(this.site.id));
        }
      } catch (e: any) {
        throw Error(e.response.data.message);
      }
    },

    async setContentOrder(data: ContentOrder[]) {
      await contentApi.setOrder(data);
    },

    async removeFieldGroup(id: number) {
      await contentApi.fieldGroupRemove(id);
    },

    clearContent() {
      this.content = {
        id: null,
        parent_id: null,
        parents_ids: [],
        content_template_id: null,
        content_group_id: null,
        title: null,
        slug: null,
        field_groups: [],
        content_template: {
          id: null,
          description: null,
          field_group_templates: [],
          name: null,
        },
      };
    },
  },
});
